import { createContext, PropsWithChildren, useContext } from 'react'

export interface VariantConfiguration {
  diameter: number | null | undefined
}

const VariantConfigurationContext = createContext<VariantConfiguration | undefined>(undefined)

export const VariantConfigurationProvider: React.FC<
  PropsWithChildren<{ value: VariantConfiguration }>
> = ({ value, children }) => {
  return (
    <VariantConfigurationContext.Provider value={value}>
      {children}
    </VariantConfigurationContext.Provider>
  )
}

export const useVariantConfiguration = () => {
  const context = useContext(VariantConfigurationContext)
  if (context === undefined) {
    throw new Error('useVariantConfiguration must be used within a VariantConfigurationProvider')
  }
  return context
}
