import {
  Box,
  Button,
  Container,
  Grid,
  GridItem,
  Stack,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react'
import type { ProductQuery, ProductVariant } from '@stocker/codegen/vendure'
import {
  useActiveChannelQuery,
  useActiveCustomerQuery,
  useActiveOrderNavigationQuery,
  useAddItemToOrderMutation,
  useCustomerSpecificPriceQuery,
  useVariantDetailsQuery,
} from '@stocker/codegen/vendure'
import { A11CommerceConfig } from '@stocker/commerce-config'
import type { IInformationTabsProps, tabProps } from '@stocker/ui-components/custom'
import {
  InformationTabs,
  ProductVariantSelector,
  VariantConfigurationProvider,
} from '@stocker/ui-components/custom'
import type { IVariant } from '@stocker/ui-components/design-system'
import {
  Breadcrumbs,
  DownloadGrid,
  ProductCardSlider,
  ProductImageSlider,
  ProductInformation,
} from '@stocker/ui-components/design-system'
import {
  PriceType,
  buildUrl,
  formatHeadlineColor,
  usePriceType,
} from '@stocker/ui-components/helpers'
import { useQueryClient } from '@tanstack/react-query'
import uniqBy from 'lodash/uniqBy'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

export const ProductPageResponsive: React.FC<{ product: NonNullable<ProductQuery['product']> }> = ({
  product,
}) => {
  const queryClient = useQueryClient()
  const {
    query: { sku },
    push: routerPush,
    replace: routerReplace,
    pathname,
    query,
    isReady,
  } = useRouter()

  const mainCollection = product.collections.length > 0 ? product.collections[0] : null

  const [selectedVariant, setSelectedVariant] = useState<ProductVariant | null>(null)
  const [activeTab, setActiveTab] = useState<string>('description')
  const [assets, setAssets] = useState(product.assets)
  const intl = useIntl()
  const toast = useToast()
  const { data: activeChannel } = useActiveChannelQuery()
  const { data: user } = useActiveCustomerQuery()
  const { priceType } = usePriceType()

  const {
    data: variantImages,
    refetch: refetchVariantImages,
    isLoading: variantImagesLoading,
  } = useVariantDetailsQuery(
    { id: selectedVariant?.id ?? '', includeImages: true },
    { enabled: false, staleTime: 1000 * 60 * 5 },
  )
  const {
    data: variantSystemInformationData,
    refetch: refetchVariantSystemInformationData,
    isLoading: variantSystemInformationLoading,
  } = useVariantDetailsQuery(
    { id: selectedVariant?.id ?? '', includeSystemInformation: true },
    { enabled: false, staleTime: 1000 * 60 * 5 },
  )
  const { data: variantAdditionalProducts, isLoading: variantAdditionalProductsLoading } =
    useVariantDetailsQuery(
      { id: selectedVariant?.id ?? '', includeAdditionalProducts: true },
      { staleTime: 1000 * 60 * 5 },
    )

  // refetch variant data when tab changes (cached by react-query, no additional request)
  useEffect(() => {
    switch (activeTab) {
      case 'images':
        refetchVariantImages()
        break
      case 'systeminformation':
        refetchVariantSystemInformationData()
        break
    }
  }, [activeTab, selectedVariant])

  // Data for systeminformation
  const systemInformation = useMemo(() => {
    return (
      variantSystemInformationData?.variant?.customFields?.systemInformationItems?.map((block) => {
        return `<strong style='color: var(--chakra-colors-accent-500); display: block;'>${String(
          block.itemHeadline ?? '',
        )}</strong>${String(block.itemText ?? '')}<br/>`
      }) ?? []
    )
  }, [variantSystemInformationData])

  // Data for gallery images
  const galleryImages = useMemo(() => {
    if (
      variantImages?.variant?.customFields?.onSiteImages &&
      variantImages.variant.customFields.onSiteImages.length > 0
    ) {
      return {
        images: variantImages.variant.customFields.onSiteImages.map((image) => ({
          fullpath: `${image.source}?w=1000&h=1000&mode=resize`,
        })),
      }
    } else if (variantImagesLoading) {
      return {
        images: [],
      }
    } else {
      return {
        text: intl.formatMessage({ id: 'product-detail--table-images-no-images' }),
      }
    }
  }, [intl, variantImages])

  // Data for informationTabs
  const informationTabs: IInformationTabsProps = useMemo(() => {
    const tabs: Array<{
      id: string
      name: string
      tabContent: tabProps
      contentLoading: boolean
    }> = [
      {
        id: 'description',
        name: intl.formatMessage({ id: 'product-detail--table-details' }),
        contentLoading: false,
        tabContent: {
          title: intl.formatMessage({ id: 'product-detail--table-details' }),
          tableValues: selectedVariant?.customFields?.customAttributes
            ?.filter((variant) => {
              // remove values when they contain empty or "is" (isolierstärke)
              return !variant.itemText?.includes('empty') && !variant.itemHeadline?.includes('is')
            })
            .map((variant) => {
              let itemText = variant.itemText

              if (itemText === '1') {
                itemText = 'Ja'
              }

              return [
                intl.formatMessage({ id: `--facet-${variant.itemHeadline ?? ''}` }),
                itemText ?? '',
              ]
            }) ?? [['label', '-------']],
          tableHead: [intl.formatMessage({ id: '--label' }), intl.formatMessage({ id: '--value' })],
          disclaimer: [intl.formatMessage({ id: 'product-detail--table-disclaimer' })],
          description: [selectedVariant?.customFields?.infoText ?? ''],
        },
      },
      {
        id: 'systeminformation',
        contentLoading: variantSystemInformationLoading,
        name: intl.formatMessage({ id: 'product-detail--table-system-information' }),
        tabContent: {
          text: systemInformation.join(''),
        },
      },
      {
        id: 'images',
        contentLoading: variantImagesLoading,
        name: intl.formatMessage({ id: 'product-detail--table-images' }),
        tabContent: galleryImages,
      },
    ]

    // if (galleryImages.length > 0) {
    //   tabs.push()
    // }

    return {
      tabs,
    }
  }, [
    galleryImages,
    intl,
    selectedVariant?.customFields?.customAttributes,
    systemInformation,
    variantImagesLoading,
    variantSystemInformationLoading,
  ])

  const downloadsMapped = useMemo(() => {
    return (
      product.customFields?.downloads?.map((download) => {
        return {
          text: formatHeadlineColor(download.fileName),
          fileSize: download.fileSize,
          fileLink: download.path.replace(/\s+/g, '-'),
        }
      }) ?? []
    )
  }, [product])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    const variantSku = sku || product.variants[0].sku
    if (!sku && isReady)
      routerReplace({ pathname, query: { ...query, sku: variantSku } }, undefined, {
        shallow: true,
      })
    const variant = product.variants.find((variant) => variant.sku === variantSku)
    if (variant) {
      setAssets(variant.assets)
      setSelectedVariant(variant as ProductVariant)
    } else {
      setAssets(product.assets)
      setSelectedVariant(null)
    }
  }, [isReady, pathname, product.assets, product.variants, query, routerReplace, sku])

  const getSubHeadings = useCallback(() => {
    const headings = uniqBy(selectedVariant?.options, 'code').map((option) => {
      return {
        text: `${intl.formatMessage({ id: `--facet-${String(option.group.name)}` })}: ${String(
          option.code,
        )} `,
        type: 'h3' as const,
        size: { base: 'md', lg: 'xl' },
        color: 'black',
      }
    })

    if (selectedVariant?.sku) {
      headings.push({
        text: selectedVariant.sku
          ? `${intl.formatMessage({ id: '--sku' })}: ${selectedVariant.sku}`
          : '',
        type: 'h3' as const,
        size: { base: 'sm', lg: 'sm' },
        color: 'black',
      })
    }

    return headings
  }, [selectedVariant?.facetValues, selectedVariant?.sku, intl])

  // adds an item to an order, only works if user is logged in
  const addItemToOrder = useAddItemToOrderMutation({
    onSuccess: () => {
      toast({
        title: 'Produkt hinzugefügt',
        description: 'Das gewählte Produkt wurde erfolgreich in den Warenkorb gelegt',
        status: 'success',
        duration: 2000,
      })
      queryClient.invalidateQueries({ queryKey: useActiveOrderNavigationQuery.getKey() })
    },
    onError: (error) => {
      // TODO: [AC-222] Add proper error displaying
      let message = 'Unknown Error'
      if (error instanceof Error) {
        message = error.message
      }
      toast({
        title: 'Error',
        description: message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  const customerSpecificPrice = useCustomerSpecificPriceQuery({
    channelCode: activeChannel?.activeChannel.code ?? '__default_channel__',
    variantId: selectedVariant?.id ?? product.id,
  })

  const onVariantChange = useCallback(
    (variant: IVariant | null) => {
      routerReplace({ pathname, query: { ...query, sku: variant?.sku ?? '' } }, undefined, {
        shallow: true,
      })
    },
    [pathname, query, routerReplace],
  )

  const shortenBreadCrumbs = useBreakpointValue({
    base: true,
    md: false,
  })

  return (
    <VariantConfigurationProvider
      value={{
        diameter: product.collections.at(0)?.customFields?.preselectedDiameter,
      }}
    >
      <Container maxW="container.page" py={{ base: '15px', lg: '40px' }}>
        {mainCollection && (
          <Box mb={{ base: '15px', lg: '35px' }}>
            <Breadcrumbs
              shortenBreadcrumbs={shortenBreadCrumbs}
              data={mainCollection.breadcrumbs
                .map((breadcrumb) => {
                  return {
                    label: breadcrumb.name,
                    slug: buildUrl('category', breadcrumb.slug),
                  }
                })
                .slice(1)}
            />
          </Box>
        )}
        <Grid templateColumns="repeat(12, 1fr)" gap={{ base: 0, lg: 7 }}>
          {/* Slider 1 */}
          <GridItem
            colSpan={{ base: 12, lg: 3 }}
            w="100%"
            bg="secondaryBackground.500"
            order={{ base: 0, lg: 1 }}
          >
            {assets.length > 0 && (
              <ProductImageSlider
                slides={assets.map((image) => {
                  return {
                    image: {
                      fullpath: `${image.source}?preset=medium`,
                    },
                    thumbnailImages: {
                      fullpath: `${image.source}?preset=small`,
                    },
                  }
                })}
                previewSliderBreakpoints={{
                  640: {
                    slidesPerView: 3,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  992: {
                    slidesPerView: 2,
                  },
                  1360: {
                    slidesPerView: 3,
                  },
                }}
              />
            )}
          </GridItem>
          <GridItem
            mt={{ base: 15, lg: 0 }}
            colSpan={{ base: 12, lg: 6 }}
            w="100%"
            bg="secondaryBackground.500"
            order={{ base: 1, lg: 2 }}
          >
            <Stack spacing={{ base: '20px', lg: '30px' }}>
              <ProductInformation
                heading={{
                  text: formatHeadlineColor(
                    selectedVariant?.customFields?.productDetailName ?? product.name,
                  ),
                  type: 'h1',
                  size: { base: '2xl', lg: '4xl' },
                }}
                subHeadings={getSubHeadings()}
                text={product.description}
              />
              <InformationTabs onChange={setActiveTab} tabs={informationTabs.tabs} />
              {/* <Show above="sm"> */}
              <ProductCardSlider
                isLoading={variantAdditionalProductsLoading}
                heading={{
                  text: 'Ähnliche Produkte',
                  type: 'h4',
                }}
                slidesPerView={{ desktop: 2, tablet: 2 }}
                productCards={
                  variantAdditionalProducts?.variant?.customFields?.additionalProducts
                    ?.filter((variant) => {
                      return variant.id !== product.id
                    })
                    .map((variant) => {
                      return {
                        image: { fullpath: variant.featuredAsset?.source },
                        heading: {
                          text: formatHeadlineColor(variant.name),
                          size: 'lg',
                          type: 'h3',
                        },
                        detailLink: buildUrl('product', variant.slug),
                        // TODO: Remove this prop in the future
                        // added in this pull request https://github.com/alpin11/stocker-webshop-frontend/pull/471
                        doNotUseNextLink: true,
                      }
                    }) ?? []
                }
              />
              {/* </Show> */}
            </Stack>
          </GridItem>
          {/* ProductConfigurator / Add to cart */}
          <GridItem
            colSpan={{ base: 12, lg: 3 }}
            w="100%"
            bg="secondaryBackground.500"
            mt={{ base: '30px', lg: '0' }}
            order={{ base: 1, lg: 3 }}
          >
            <Stack spacing={{ base: '15px', lg: '30px' }}>
              <Box bg="gray.50" p="15px">
                {isReady && (
                  <ProductVariantSelector
                    variantSelectorProps={{
                      variants: uniqBy(product.variants, 'options') as IVariant[],
                      initialSku: sku as string | undefined,
                    }}
                    basePrice={
                      customerSpecificPrice.data?.customerSpecificPrice
                        ? customerSpecificPrice.data.customerSpecificPrice.basePrice
                        : undefined
                    }
                    // this is pretty ugly but typescript wont let me fix it in another way so i have to do it like this
                    discountPrice={
                      priceType === PriceType.Partner
                        ? (customerSpecificPrice.data?.customerSpecificPrice
                            ? customerSpecificPrice.data.customerSpecificPrice.price
                            : undefined) ===
                          (customerSpecificPrice.data?.customerSpecificPrice
                            ? customerSpecificPrice.data.customerSpecificPrice.basePrice
                            : undefined)
                          ? undefined
                          : customerSpecificPrice.data?.customerSpecificPrice
                          ? customerSpecificPrice.data.customerSpecificPrice.price
                          : undefined
                        : undefined
                    }
                    onVariantChange={onVariantChange}
                    addItemToOrder={user?.activeCustomer ? addItemToOrder : undefined}
                    // here we check the a11 config if we should only show the stock status when the user is logged in
                    hideStock={
                      A11CommerceConfig.product.onlyShowStockStatusWhenLoggedIn
                        ? !user?.activeCustomer
                        : false
                    }
                  />
                )}
                {!user?.activeCustomer && (
                  <Link href="/auth/login">
                    <Button mt="15px" width="100%" rounded="md" colorScheme="accent" type="submit">
                      {intl.formatMessage({ id: '--log-in-to-buy' })}
                    </Button>
                  </Link>
                )}
              </Box>
              {downloadsMapped.length > 0 && (
                <Box bg="gray.50" px="15px">
                  <DownloadGrid
                    heading={{
                      type: 'h4',
                      text: intl.formatMessage({ id: '--facet-downloads' }),
                    }}
                    columns={1}
                    downloadItems={downloadsMapped}
                  />
                </Box>
              )}
            </Stack>
          </GridItem>
        </Grid>
      </Container>
    </VariantConfigurationProvider>
  )
}
