import { Box, Button, Flex, HStack, Skeleton, Stack, Text } from '@chakra-ui/react'
import { A11CommerceConfig } from '@stocker/commerce-config'
import type {
  ICurrentRefinemt,
  IVariant,
  IVariantSelectorProps,
} from '@stocker/ui-components/design-system'
import {
  AvailabilityStatus,
  FiRsShoppingBag,
  IncrementAmountInput,
  VariantSelector,
  getInitialVariant,
} from '@stocker/ui-components/design-system'
import { useRouter } from 'next/router'
import type React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { FormattedNumber, useIntl } from 'react-intl'
import { useVariantConfiguration } from '../../provider'
import { WishlistModal } from '../WishlistModal'

export interface IProductVariantSelectorProps {
  variantSelectorProps: Omit<IVariantSelectorProps, 'onVariantChange' | 'setFirstVariantAsInitial'>
  onVariantChange: IVariantSelectorProps['onVariantChange']
  addItemToOrder?: {
    mutate: (input: { productVariantId: string; quantity: number }) => void
    isLoading: boolean
  }
  hidePrice?: boolean
  hideStock?: boolean
  // Price is here not on the variant because we make an extra request for it (Customer Specific Price)
  basePrice?: number
  discountPrice?: number
  searchString?: string
  currentRefinemt?: ICurrentRefinemt
  enablePreSelectedOption?: boolean
}

export interface IFacetWithOptions {
  title: string
  inputPlaceholder?: string
  required?: boolean
  options: IFacetOption[]
}

export interface IFacetOption {
  label: string
  value: string
}

export const ProductVariantSelector: React.FC<IProductVariantSelectorProps> = ({
  variantSelectorProps,
  onVariantChange,
  basePrice,
  discountPrice,
  addItemToOrder,
  hidePrice = false,
  hideStock = false,
  searchString,
  currentRefinemt,
  enablePreSelectedOption = false,
}) => {
  const intl = useIntl()
  const router = useRouter()
  const [selectedVariant, setSelectedVariant] = useState<IVariant | null>(null)
  const [purchaseQuantity, setPurchaseQuantity] = useState(1)
  const [selectionIsFinished, setSelectionIsFinished] = useState(selectedVariant !== null)
  const variantConfiguration = useVariantConfiguration()

  useEffect(() => {
    // this useEffect is here because sometimes when changing products and not selecting a variant before adding it to the cart the product you came from gets added to the cart instead
    // we prevent this by setting the initialVariant everytime the router changes
    const initialVariant = getInitialVariant(
      variantSelectorProps.variants,
      variantSelectorProps.initialSku,
      variantConfiguration,
      A11CommerceConfig.product.setFirstVariantAsInitial,
    )
    setSelectedVariant(initialVariant)
  }, [router.query.slug])

  const onSubmit = useCallback(() => {
    if (selectedVariant?.id && purchaseQuantity && addItemToOrder) {
      addItemToOrder.mutate({
        productVariantId: String(selectedVariant.id),
        quantity: purchaseQuantity,
      })
    }
  }, [addItemToOrder, purchaseQuantity, selectedVariant])

  return (
    <Stack spacing="10px">
      <VariantSelector
        {...variantSelectorProps}
        onVariantChange={(variant) => {
          if (variant) {
            setSelectionIsFinished(true)
            setSelectedVariant(variant)
            onVariantChange(variant)
          } else {
            setSelectionIsFinished(false)
            // setSelectedVariant(null) // Uncomment this if the strategy changes and we want to show NO variant
          }
        }}
        setFirstVariantAsInitial={A11CommerceConfig.product.setFirstVariantAsInitial}
      />
      {selectedVariant && (
        <>
          <Box>
            {!hidePrice && !basePrice && basePrice !== 0 && <Skeleton h="24px" w="40%" />}
            <Box>
              {/* Price */}
              {!hidePrice && basePrice && basePrice > 0 && (
                <Stack spacing="5px" pr="10px" align="flex-end" py="10px">
                  <Text fontSize="xl" fontWeight="bold" fontFamily="primary" textAlign="right">
                    {discountPrice ? (
                      <>
                        <HStack>
                          <Text
                            display="inline"
                            fontSize="sm"
                            color="red"
                            as="del"
                            fontWeight="bold"
                            fontFamily="primary"
                          >
                            <FormattedNumber
                              value={basePrice / 100}
                              style="currency"
                              currency={selectedVariant.currencyCode}
                              minimumFractionDigits={2}
                              maximumFractionDigits={2}
                            />
                          </Text>
                          <Text display="inline" color="red" fontSize="md" fontFamily="primary">
                            {`${(((discountPrice - basePrice) * 100) / basePrice).toFixed(0)}%`}
                          </Text>
                        </HStack>
                        <Text
                          display="inline"
                          fontSize="xl"
                          fontWeight="bold"
                          fontFamily="primary"
                          textAlign="right"
                        >
                          <FormattedNumber
                            value={discountPrice / 100}
                            style="currency"
                            currency={selectedVariant.currencyCode}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                          />
                        </Text>
                      </>
                    ) : (
                      <FormattedNumber
                        value={basePrice / 100}
                        style="currency"
                        currency={selectedVariant.currencyCode}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    )}
                  </Text>
                  <Text fontSize="xs" lineHeight={1}>
                    {intl.formatMessage({ id: '--price-info-text' })}
                  </Text>
                </Stack>
              )}
              {/* Availability */}
              {!hideStock && (
                <Flex mt="5px" justify="flex-end">
                  <AvailabilityStatus availabilityStatus={selectedVariant.stockLevel} />
                </Flex>
              )}
            </Box>
          </Box>
          {/* Quantity */}
          {(A11CommerceConfig.product.allowOrderingOutOfStockProducts
            ? addItemToOrder
            : addItemToOrder && selectedVariant.stockLevel === 'OUT_OF_STOCK') && (
            <Flex hidden={!selectionIsFinished} justify="space-between" align="center">
              {/* <UnitSelection heading={intl.formatMessage({ id: 'product-detail-package-unit' })} units={packageUnits ?? []} onChangeAmount={setPackageUnit} selectedUnit={packageUnit ?? ''}/> */}
              <Box h="fit-content">
                <IncrementAmountInput
                  min={1}
                  max={999}
                  amount={purchaseQuantity}
                  onChange={setPurchaseQuantity}
                />
              </Box>
            </Flex>
          )}
        </>
      )}
      {addItemToOrder && (
        <HStack>
          <Button
            w="100%"
            disabled={
              A11CommerceConfig.product.allowOrderingOutOfStockProducts
                ? !selectionIsFinished
                : !selectionIsFinished || selectedVariant?.stockLevel === 'OUT_OF_STOCK'
            }
            rounded="md"
            colorScheme="accent"
            type="submit"
            isLoading={addItemToOrder.isLoading}
            onClick={onSubmit}
          >
            <HStack>
              <FiRsShoppingBag />
              <Text>{intl.formatMessage({ id: 'product-detail--add-to-cart-label' })}</Text>
            </HStack>
          </Button>
          {selectedVariant?.id && <WishlistModal productId={String(selectedVariant.id)} />}
        </HStack>
      )}
    </Stack>
  )
}
